import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import TableCollapsible from '../../Tables/TableColapseNoPaginationDoacao';
import api from '@http/api';
import { CurrencyFormat, FormatDate } from '@utils/formats';
import { getColors } from '@styles/colorsTheme';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import InvisibleContent from '@components/Shared/InvisibleContent';

import Services, { IModelo } from './services';
import { useSnackbar } from 'notistack';


interface IProps {
  idPerfil: number;
}

const GetDoacoes: React.FC<IProps> = ({ idPerfil }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IModelo[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [resumoDoacaoMedia, setResumoDoacaoMedia] = useState('');
  const [resumoDoacaoUltimaData, setResumoDoacaoUltimaData] = useState('');
  const [resumoDoacaoPrimeiraData, setResumoDoacaoPrimeiraData] = useState('');
  const [resumoDoacaoTotal, setResumoDoacaoTotal] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const [ultimaDoacaoId, setUltimaDoacaoId] = useState(0);
  const [qntItemsPagina, setQntItemsPagina] = useState(4);

  const columnsLabel = {
    data_doacao: 'Data',
    origem_doacao_descricao: 'Origem',
    campanha_descricao: 'Campanha',
    valor_doacao: 'Valor',
    observacao: 'Observacao',
  };

  async function carregarDoacao() {
    if (qntItemsPagina > 4){
      loadData();
    }
  }

  async function loadData() {
    setLoading(true);
    await api
      .get( `/api/Doacao/FindDoacoesByPerfil?idPerfil=${idPerfil}&ultimaDoacaoId=${ultimaDoacaoId}&qntItemsPagina=${qntItemsPagina}`)
      .then(response => {
        const items = response.data.doacoes;
        setUltimaDoacaoId(response.data.ultimoId);
        const itemData: IModelo[] = items.map((item: IModelo) => ({
          data_doacao: FormatDate(item.data_doacao),
          valor_doacao: CurrencyFormat(parseFloat(item.valor_doacao)),
          origem_doacao_descricao: item.origem_doacao_descricao,
          campanha_descricao: item.campanha_descricao,
          observacao: item.observacao || '',
        }));

        setData(itemData);
        setLoading(false);
      })
      .catch(error => {
       // alert(error);
        enqueueSnackbar(`Ocorreu um erro`, {
          title: 'Ocorreu um erro ',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoading(false);
      });

      setQntItemsPagina(qntItemsPagina + 4);
  }

  useEffect(() => {
    async function loadResumoDoacoes() {
      const services = new Services();
      const { error, response } = await services.getResumoDoacao({idPerfil});
      if (!error && response) {
        setResumoDoacaoMedia(response.media_doacoes);
        setResumoDoacaoPrimeiraData(FormatDate(response.primeira_doacao));
        setResumoDoacaoUltimaData(FormatDate(response.ultima_doacao));
        setResumoDoacaoTotal(response.total_doacoes)
      }
    }
    loadResumoDoacoes();

    {/* TABELA */}
    loadData();

  }, [idPerfil]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(item =>
    item.observacao.toLowerCase().includes(searchTerm.toLowerCase())
  );

  let compararDoacao = (resumoDoacaoTotal / 4);


  //console.log('socorro', qntItemsPagina)

  //console.log('socorro222222', teste2)

  return (
    <>
      <Grid
        container
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          marginTop: 2,
          marginBottom: 0,
        }}
        justify="center"
        alignItems="center"
      >
          <Grid container justify="center" alignItems="center">
            <Grid item sm={6} xs={12}>
              <Grid container>
                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Média doação:{' '}
                      <strong>
                        {CurrencyFormat(
                          parseFloat(resumoDoacaoMedia),
                        )}{' '}
                      </strong>
                  </p>
                </Grid>

                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Total doação:{' '}
                      <strong>
                        {resumoDoacaoTotal}
                      </strong>
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12}>
              <Grid container>
                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Primeira doação em:{' '}
                      <strong>
                        {(
                          resumoDoacaoPrimeiraData
                          ) === '01/01/0001'
                          ? '--/--/----'
                          : (
                            resumoDoacaoPrimeiraData
                        )}
                      </strong>
                  </p>
                </Grid>

                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Última doação em:{' '}
                      <strong>
                        {(
                          resumoDoacaoUltimaData
                          ) === '01/01/0001'
                          ? '--/--/----'
                          : (
                          resumoDoacaoUltimaData
                        )}
                      </strong>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Grid>

      <Grid container>
        <TextField
          label="Pesquisar"
          variant="outlined"
          margin="normal"
          style={{width: '100%'}}
          value={searchTerm}
          onChange={handleSearchChange}
          InputLabelProps={{
            style: {
              top: '-2px',
            }
          }}
        />
      </Grid>


        <TableCollapsible
          loading={loading}
          columns={[columnsLabel]}
          FieldsBold={['valor_doacao']}
          data={filteredData}
          keys={['Data', 'valor', 'Origem', 'Campanha', 'Detalhes']}
          ItemBottom={
            <Grid container justify="flex-end" style={{ marginTop: 20, paddingBottom: 30 }}>
              <p>
                {/*<InvisibleContent visible={teste2 > qntItemsPagina }>*/}
                <IconButton
                    //aria-label="download"
                    onClick={carregarDoacao}
                    disabled={compararDoacao < qntItemsPagina}
                  >
                    <ExpandMoreTwoToneIcon />
                  </IconButton>
              </p>
            </Grid>
          }

        />

    </>
  );
}
export default GetDoacoes;

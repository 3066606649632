import api from '@http/api';
import { FormatDate,CurrencyFormat } from '@utils/formats';
import { MaskCPFeCNPJ } from '@utils/Masks';


export interface IBuscaDetalhada {
  id_tipo_perfil: number;
  estado: string;
  id_forma_contribuicao: number;
  id_tipo_correspondencia: number;
  data_procura_inicial: string;
  data_procura_final: string;
  tipo_ocorrencia: string;
  gerar_arquivo: boolean;
  page_number: number;
  page_size: number;
}


export interface IBuscaDetalhadaDoacao {
  data_inicial: string;
  data_final: string;
  gerar_arquivo: boolean;
  page_number: number;
  page_size: number;
}

export interface IBuscaDetalhadaInativos {
  data_procura_inicial: string;
  data_procura_final: string;
  gerar_arquivo: boolean;
  page_number: number;
  page_size: number;
}


export interface IBuscaPrevisaoDoacao {
  modelo: IConsultaDoacoesPrevisaoCredito[];
  link_arquivo: string;
  quantidade_item_pagina: number;
  total_pagina: number;
  total_itens: number;
  pagina_anterior_existe: boolean;
  pagina_seguinte_existe: boolean;
  quantidade_cadastros_atualizar: number;
}

export interface IBuscaMalaMensalEnviada {
  mes_estrategia: string;
  ano_estrategia: any;
  gerar_aquivo: boolean;
  page_number: number;
  page_size: number;
}

export interface IBuscaArrecadacoesDebito {
  data_inicial: string;
  data_final: string;
  id_conveio: number;
  gerar_arquivo: boolean;
  page_number: number;
  page_size: number;
}

export interface IBuscaRemessaRetorno {
  //data_inicial: string;
  //data_final: string;
  id_conveio: number;
  tipo_convenio: string;
  gerar_arquivo: boolean;
  page_number: number;
  page_size: number;
}

export interface IConsultaEstado {
 id_cidade: number;
 cidade: string;
 quantidade_cadastros_ativos: number;
}

export interface IBuscaOcorrencia {
  id_tipo_perfil: number;
  estado: string;
  id_forma_contribuicao: number;
  id_tipo_correspondencia: number;
  id_convenio: number;
  data_procura_inicial: string;
  data_procura_final: string;
  tipo_ocorrencia: string;
  gerar_arquivo: boolean;
  page_number: number;
  page_size: number;
}

export interface IConsultaPessoa {
  id_pessoa: number;
  nome: string;
  email: string;
  forma_contribuicao: string;
  tipo_correspondencia: string;
  tipo_perfil: string;
  data_aniversario: string;
  nome_fantasia: string;
  valor_total_doacao: string;
  qntd_doacao: number;
  origem_cadasro: string;
 }

 export interface IConsultaOcorrencia {
  id_pessoa: number;
  nome_pessoa: string;
  status_ocorrencia: string;
  convenio: string;
  data_vencimento: string;
 }

 export interface IConsultaDoacaoEmail {
  id_pessoa: number;
  nome: string;
  email: string;
  valor_doado: string;
  vezes_doadas: number;
 }

 export interface IConsultaOrigemDoacao {
  item1: string;
  item2: string;
  item3: string;
 }

 export interface IConsultaFaixaEtaria {
  item1:{
    item1: string;
  }

  item2:{
    item2: string;
  }
  item3:{
    item3: string;
  }

 }

 export interface IConsultaDoacaoValor {
  item1: string;
  item2: string;
  item3: string
  item4: string;
 }

 export interface IConsultaDoacaoQuantidade {
  item1: string;
  item2: string;
  item3: string;
  item4: string;
 }

 export interface IConsultaAniversariante {
  id_pessoa: number;
  nome: string;
  tipo_perfil: string;
  data_aniversario: string;
 }

 export interface IConsultaCadastroOperador {
  codigoUsuario: number;
  nomeUsuario: string;
  data_cadastro: string;
  idCadastro: string;
  nomeCadastro: string;
  apelido: string;
  documento: string;
  tipo_perfil: string;
  origem_cadastro: string;
  forma_contribuicao: string;
  recorrente: string;
  tipo_correspondencia: string;
  endereco: string;
  bairro: string;
  complemento: string;
  cidade: string;
  estado: string;

 }

export interface IOrigemEspecifica {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}


export interface IFormaContribuicao {
  id: number;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface ICorrespondencia {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IPerfil {
  id: number;
  descricao: string;
  status_ativo: boolean;
}

export interface IResponseGetCidadesByUf {
  cidade_id: string;
  cidade_nome: string;
}

export interface ICidade {
  id: string | number;
  label: string;
}

interface IResponse {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    estados: IConsultaEstado[];
  };
}

export interface IConvenio {
  id: number;
  numero_convenio: string;
  nome_cedente: number | string | null;
  numero_agencia: number | string | null;
  digito_agencia: number | string | null;
  numero_conta: number | string | null;
  digito_cedente: number | string | null;
  id_banco: number | string;
  descricao: string;
}

export interface IConsultaPrimeirosDoadoresByPeriodo {
  data_cadastro: string;
  id_pessoa: string;
  nome: string;
  documento: string;
  tipo_perfil: string;
  forma_contribuicao: string;
  data_doacao: string;
  valor_doacao: string;
 }

 export interface IConsultaFormaDoacao {
  item1: string;
  item2: string;
  item3: string;
  item4: string;
 }

 export interface IConsultaDoacoesPFePJ {
  item1: string;
  item2: string;
  item3: string;
 }

 export interface IConsultaDoacoesPrevisaoCredito {
  item1: string;
  item2: string;
  item3: string;
 }

 export interface IConsultaBoletoStatus {
  usuario: string;
  numeroEmisssao: string;
  dataEmissaoBoleto: string;
  dataVencimentoBoleto: string;
  statusBoleto: string;
  idCrm: string;
  nomePessoa: string;
  estrategia: string;
 }

 export interface IConsultaCadastroReativados {
  idPessoa: string;
  nomePessoa: string;
  origemCadastro: string;
  motivoCadastro: string;
  formaContribuicao: string;
  tipoCorrespondencia: string;
 }

 export interface IConsultaPalitagens {
  //codigoUsuario: string;
  nomeUsuario: string;
  idCadastro: string;
  nomeCadastro: string;
  diaLigacao: string;
  horarioInicioLigacao: string;
  horarioFimLigacao: string;
  observacao: string;
  statusLigacao: string;
  estrategiaLigacao: string;
 }

 export interface IConsultaMalaMensalEnviada {
  tipoEnvio: string;
  qntDoacoes: string;
  valorTotalDoacao: string;
  mediaDoacao: string;
 }

 export interface IConsultaRemessaRetorno {
  convenio: string;
  banco: string;
  arquivo: string;
  //status: string;
  boletosAguardandoRetorno: number;
  boletosAguardandoEnvio: number;
  boletosRejeitados: number;
  boletosPagos: number;
  boletosRegistrados: number;
  boletosVencidos: number;
  aguardandoRetorno: number;
 }


 export interface IConsultaArrecadacoesDebito {
  id: string;
  nome: string;
  vencimento: string;
  valor: string;
  descricao: string;
  banco: string;
 }

 export interface IConsultaPrimeiraDoacaoPessoaMes {
  nome: string;
  nome_fantasia: string;
  documento: string;
  valor_primeira_doacao: string;
  data_primeira_doacao: string;
 }

 export interface IConsultaInativosSemContrib {
  id_pessoa: number;
  nomeCompleto: string;
  FormaContribuicao: string;
  datadecadastro: string;
 }



class PageConsultasServices {

  public async loadOrigemEspecifica() {
    return await api
      .get<IOrigemEspecifica[]>('/api/OrigemEspecifica/GetOrigensEspecificas')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadCorrespondencia() {
    return await api
      .get<ICorrespondencia[]>('/api/TipoCorrespondencia/GetTiposCorrespondecia')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetTiposPerfil() {
    return await api
      .get<IPerfil[]>('api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async getCidadesByUF(estado: string) {
    return await api
      .post<IResponseGetCidadesByUf[]>('/api/ContatoOffline/FindCidades', {
        uf: estado,
      })
      .then(response => {
        let reescrito: ICidade[] = [];

        response.data.map(cidade => {
          const obj: ICidade = {
            id: cidade.cidade_id,
            label: cidade.cidade_nome,
          };

          return reescrito.push(obj);
        });

        return {
          error: false,
          response: reescrito,
        };
      })
      .catch(() => {
        return {
          error: true,
          response: [],
        };
      });
  }


  public async buscaConsultaEstado({
    id_tipo_perfil,
    estado,
    id_forma_contribuicao,
    id_tipo_correspondencia,
    data_procura_inicial,
    data_procura_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhada) {
    return await api
      .post('api/Consulta/FindCadastrosAtivosByEstado', {
        id_tipo_perfil,
        estado,
        id_forma_contribuicao,
        id_tipo_correspondencia,
        data_procura_inicial,
        data_procura_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const estados = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemEstados: IConsultaEstado[] = [];

        estados.map((item: IConsultaEstado) => {
          return itemEstados.push({
            id_cidade: item.id_cidade,
            cidade: item.cidade,
            quantidade_cadastros_ativos: item.quantidade_cadastros_ativos,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, estados: itemEstados },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaPessoa({
    id_tipo_perfil,
    estado,
    id_forma_contribuicao,
    id_tipo_correspondencia,
    data_procura_inicial,
    data_procura_final,
    tipo_ocorrencia,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhada) {
    return await api
      .post('api/Consulta/FindPessoasByFiltro', {
        id_tipo_perfil,
        estado,
        id_forma_contribuicao,
        id_tipo_correspondencia,
        data_procura_inicial,
        data_procura_final,
        tipo_ocorrencia,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const pessoas = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemPessoas: IConsultaPessoa[] = [];

        pessoas.map((item: IConsultaPessoa) => {
          return itemPessoas.push({
            id_pessoa: item.id_pessoa,
            nome: item.nome,
            tipo_perfil: item.tipo_perfil,
            nome_fantasia: item.nome_fantasia,
            origem_cadasro: item.origem_cadasro,
            tipo_correspondencia: item.tipo_correspondencia,
            forma_contribuicao: item.forma_contribuicao,
            email: item.email,
            data_aniversario: item.data_aniversario,
            valor_total_doacao: CurrencyFormat(parseFloat(item.valor_total_doacao)),
            qntd_doacao: item.qntd_doacao,

          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, pessoas: itemPessoas },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaFaixaEtaria({
    id_tipo_perfil,
    estado,
    id_forma_contribuicao,
    id_tipo_correspondencia,
    data_procura_inicial,
    data_procura_final,
    tipo_ocorrencia,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhada) {
    return await api
      .post('api/Consulta/FindCadastrosAtivosByFaixaIdade', {
        id_tipo_perfil,
        estado,
        id_forma_contribuicao,
        id_tipo_correspondencia,
        data_procura_inicial,
        data_procura_final,
        tipo_ocorrencia,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const faixas = response.data.modelo;

        console.log(faixas);
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemFaixas: IConsultaFaixaEtaria[] = [];

        faixas.map((item: IConsultaFaixaEtaria) => {
          return itemFaixas.push({
            item1: item.item1,
            item2: item. item2,
            item3: item.item3,

          });
        });
        console.log('respon', faixas);
        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo,faixas: itemFaixas },
        };
      })

      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaConsultaOcorrencia({
    id_tipo_perfil,
    estado,
    id_forma_contribuicao,
    id_tipo_correspondencia,
    id_convenio,
    data_procura_inicial,
    data_procura_final,
    tipo_ocorrencia,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaOcorrencia) {
    return await api
      .post('api/Consulta/FindPessoasByOcorrenciaByFormaContribuicaoAndPeriodo', {
        id_tipo_perfil,
        estado,
        id_forma_contribuicao,
        id_tipo_correspondencia,
        id_convenio,
        data_procura_inicial,
        data_procura_final,
        tipo_ocorrencia,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const ocorrencias = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemOcorrencias: IConsultaOcorrencia[] = [];

        ocorrencias.map((item: IConsultaOcorrencia) => {
          return itemOcorrencias.push({
            id_pessoa: item.id_pessoa,
            nome_pessoa: item.nome_pessoa,
            status_ocorrencia: item.status_ocorrencia,
            convenio: item.convenio,
            data_vencimento: FormatDate(item.data_vencimento),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, ocorrencias: itemOcorrencias },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaAniversariante({
    id_tipo_perfil,
    estado,
    id_forma_contribuicao,
    id_tipo_correspondencia,
    data_procura_inicial,
    data_procura_final,
    tipo_ocorrencia,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhada) {
    return await api
      .post('api/Consulta/FindPessoasAtivasAndAniversariantesByPeriodo', {
        id_tipo_perfil,
        estado,
        id_forma_contribuicao,
        id_tipo_correspondencia,
        data_procura_inicial,
        data_procura_final,
        tipo_ocorrencia,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {

        const aniversariantes = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemAniversariantes: IConsultaAniversariante[] = [];


        aniversariantes.map((item: IConsultaAniversariante) => {
          return itemAniversariantes.push({
            id_pessoa: item.id_pessoa,
            nome: item.nome,
            tipo_perfil: item.tipo_perfil,
            data_aniversario: FormatDate(item.data_aniversario),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, aniversariantes: itemAniversariantes, },


        };

      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaDoacaoEmail({
    id_tipo_perfil,
    estado,
    id_forma_contribuicao,
    id_tipo_correspondencia,
    data_procura_inicial,
    data_procura_final,
    tipo_ocorrencia,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhada) {
    return await api
      .post('api/Consulta/FindPessoasAtivasWithDoacaoAndEmail', {
        id_tipo_perfil,
        estado,
        id_forma_contribuicao,
        id_tipo_correspondencia,
        data_procura_inicial,
        data_procura_final,
        tipo_ocorrencia,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacaoEmail = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemDoacaoEmail: IConsultaDoacaoEmail[] = [];

        doacaoEmail.map((item: IConsultaDoacaoEmail) => {
          return itemDoacaoEmail.push({
            id_pessoa: item.id_pessoa,
            nome: item.nome,
            email: item.email,
            valor_doado:   CurrencyFormat(parseFloat(item.valor_doado)),
            vezes_doadas: item.vezes_doadas,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacaoEmail: itemDoacaoEmail },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaOrigemDoacao({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/FindOrigemDoacoes', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const origemDoacao = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemOrigemDoacao: IConsultaOrigemDoacao[] = [];

        origemDoacao.map((item: IConsultaOrigemDoacao) => {
          return itemOrigemDoacao.push({
           item1: item.item1,
           item2: item.item2,
           item3:  CurrencyFormat(parseFloat(item.item3)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, origemDoacao: itemOrigemDoacao },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaDoacaoValor({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListFaixaDoacaoByValor', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacaoValor = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemDoacaoValor: IConsultaDoacaoValor[] = [];

        doacaoValor.map((item: IConsultaDoacaoValor) => {
          return itemDoacaoValor.push({
            item1: item.item1,
            item2: item. item2,
            item3: item.item3,
            item4: CurrencyFormat(parseFloat(item.item4)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacaoValor: itemDoacaoValor },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaDoacaoQuantidade({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListFaixaDoacaoByVezesDoadas', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacaoQuantidade = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemDoacaoQuantidade: IConsultaDoacaoQuantidade[] = [];

        doacaoQuantidade.map((item: IConsultaDoacaoQuantidade) => {
          return itemDoacaoQuantidade.push({
            item1: item.item1,
            item2: item. item2,
            item3: item.item3,
            item4: CurrencyFormat(parseFloat(item.item4)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacaoQuantidade: itemDoacaoQuantidade },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetConvenios(formaContribuicaoID: string | number) {
    return await api
      .get<IConvenio[]>(
        `api/Financeiro/GetFinanceiroConveniosByFormaContribuicao?formaContribuicaoId=${formaContribuicaoID}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }


  public async buscaConsultaPrimeirosDoadoresByPeriodo({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/FindPrimeirosDoadoresByPeriodo', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacaoPrimeirosDoadores = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemDoacaoPrimeirosDoadores: IConsultaPrimeirosDoadoresByPeriodo[] = [];

        doacaoPrimeirosDoadores.map((item: IConsultaPrimeirosDoadoresByPeriodo) => {
          return itemDoacaoPrimeirosDoadores.push({
            data_cadastro: FormatDate(item.data_cadastro),
            id_pessoa: item.id_pessoa,
            nome: item.nome,
            documento: item.documento,
            tipo_perfil: item.tipo_perfil,
            forma_contribuicao: item.forma_contribuicao,
            data_doacao: FormatDate(item.data_doacao),
            valor_doacao: CurrencyFormat(parseFloat(item.valor_doacao)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacaoPrimeirosDoadores: itemDoacaoPrimeirosDoadores },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaDoacoesByFormaDoacao({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListDoacoesByFormaDoacao', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacoesFormaDoacao = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemdoacoesFormaDoacao: IConsultaFormaDoacao[] = [];

        doacoesFormaDoacao.map((item: IConsultaFormaDoacao) => {
          return itemdoacoesFormaDoacao.push({
            item1: item.item1,
            item2: item. item2,
            item3: CurrencyFormat(parseFloat(item.item3)),
            item4: item.item4,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacoesFormaDoacao: itemdoacoesFormaDoacao },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaConsultaDoacoesPFePJ({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListDoacoesPessoasFisicasAndPessoasJuridicasByPeriodo', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacoesPFePJ= response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemdoacoesPFePJ: IConsultaDoacoesPFePJ[] = [];

        doacoesPFePJ.map((item: IConsultaDoacoesPFePJ) => {
          return itemdoacoesPFePJ.push({
            item1: item.item1,
            item2: item.item2,
            item3: CurrencyFormat(parseFloat(item.item3)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacoesPFePj: itemdoacoesPFePJ },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaPrevisaoCredito(gerar_arquivo: boolean) {
    return await api
    .post<IBuscaPrevisaoDoacao>(
      `/api/Consulta/ListPrevisaoDeCredito?gerar_arquivo=${gerar_arquivo}`,
    )
      .then(response => {
        const doacoesPrevisaCredito= response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemdoacoesPrevisaoCredito: IConsultaDoacoesPrevisaoCredito[] = [];

        doacoesPrevisaCredito.map((item: IConsultaDoacoesPrevisaoCredito) => {
          return itemdoacoesPrevisaoCredito.push({
            item1: item.item1,
            item2: item. item2,
            item3: CurrencyFormat(parseFloat(item.item3)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacoesPrevisaoCredito: itemdoacoesPrevisaoCredito },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaBoletoStatus({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListBoletoStatusByPeriodo', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const doacoesStatusBoleto = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemdoacoesStatusBoleto: IConsultaBoletoStatus[] = [];

        doacoesStatusBoleto.map((item: IConsultaBoletoStatus) => {
          return itemdoacoesStatusBoleto.push({
            usuario: item.usuario,
            numeroEmisssao: item.numeroEmisssao,
            dataEmissaoBoleto: FormatDate(item.dataEmissaoBoleto),
            dataVencimentoBoleto: FormatDate(item.dataVencimentoBoleto),
            statusBoleto: item.statusBoleto,
            idCrm: item.idCrm,
            nomePessoa: item.nomePessoa,
            estrategia: item.estrategia,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, doacoesStatusBoleto: itemdoacoesStatusBoleto },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaCadastroReativados({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListCadastrosReativadosByPeriodo', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const cadastroReativados = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemcadastrosReativados: IConsultaCadastroReativados[] = [];

        cadastroReativados.map((item: IConsultaCadastroReativados) => {
          return itemcadastrosReativados.push({
            idPessoa: item.idPessoa,
            nomePessoa: item.nomePessoa,
            origemCadastro: item.origemCadastro,
            motivoCadastro: item.motivoCadastro,
            formaContribuicao: item.formaContribuicao,
            tipoCorrespondencia: item.tipoCorrespondencia,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, cadastroReativados: itemcadastrosReativados },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaPalitagens({
    data_inicial,
    data_final,
    page_number,
    gerar_arquivo,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListPalitagensPorPeriodo', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const listaPalitagens = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemlistaPalitagens: IConsultaPalitagens[] = [];

        listaPalitagens.map((item: IConsultaPalitagens) => {
          return itemlistaPalitagens.push({
            //codigoUsuario: item.codigoUsuario,
            nomeUsuario: item.nomeUsuario,
            idCadastro: item.idCadastro,
            nomeCadastro: item.nomeCadastro,
            diaLigacao: item.diaLigacao,
            horarioInicioLigacao: item.horarioInicioLigacao,
            horarioFimLigacao: item.horarioFimLigacao,
            observacao: item.observacao,
            statusLigacao: item.statusLigacao,
            estrategiaLigacao: item.estrategiaLigacao,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, listaPalitagens: itemlistaPalitagens },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaMalaMensalEnviada({
    mes_estrategia,
    ano_estrategia,
    gerar_aquivo,
    page_number,
    page_size,
  }: IBuscaMalaMensalEnviada) {
    return await api
      .post('api/Consulta/ListMalaMensalEnviada', {
        mes_estrategia,
        ano_estrategia,
        gerar_aquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const malaMensalEnviada = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemmalaMensalEnviada: IConsultaMalaMensalEnviada[] = [];

        malaMensalEnviada.map((item: IConsultaMalaMensalEnviada) => {
          return itemmalaMensalEnviada.push({
            tipoEnvio: item.tipoEnvio,
            qntDoacoes: item.qntDoacoes,
            valorTotalDoacao: CurrencyFormat(parseFloat(item.valorTotalDoacao)),
            mediaDoacao: CurrencyFormat(parseFloat(item.mediaDoacao)),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, malaMensalEviada: itemmalaMensalEnviada },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaArrecadacoesDebito({
    data_inicial,
    data_final,
    id_conveio,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaArrecadacoesDebito) {
    return await api
      .post('api/Consulta/ListArrecadacoesDebitoByPeriodo', {
        data_inicial,
        data_final,
        id_conveio,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const arrecadacoesDebito = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemarrecadacoesDebito: IConsultaArrecadacoesDebito[] = [];

        arrecadacoesDebito.map((item: IConsultaArrecadacoesDebito) => {
          return itemarrecadacoesDebito.push({
            id: item.id,
            nome: item.nome,
            vencimento: FormatDate(item.vencimento),
            valor: CurrencyFormat(parseFloat(item.valor)),
            descricao: item.descricao,
            banco: item.banco,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, arrecadacoesDebito: itemarrecadacoesDebito },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaEnvioRemessaRetorno({
    id_conveio,
    tipo_convenio,
    //id_conveio,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaRemessaRetorno) {
    return await api
      .post('api/Consulta/ListRemessasRetornosByBanco', {
        id_conveio,
        tipo_convenio,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const remessaRetorno = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemarremessaRetorno: IConsultaRemessaRetorno[] = [];

        remessaRetorno.map((item: IConsultaRemessaRetorno) => {
          return itemarremessaRetorno.push({
            convenio: item.convenio,
            banco: item.banco,
            arquivo: item.arquivo,
            boletosAguardandoRetorno: item.boletosAguardandoRetorno,
            boletosAguardandoEnvio: item.boletosAguardandoEnvio,
            boletosRejeitados: item.boletosRejeitados,
            boletosPagos: item.boletosPagos,
            boletosRegistrados: item.boletosRegistrados,
            boletosVencidos: item.boletosVencidos,
            aguardandoRetorno: item.aguardandoRetorno,
            //status:item.status,
            //descricao: item.descricao,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, remessaRetorno: itemarremessaRetorno },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: {},
        };
      });
  }

  public async buscaConsultaPrimeiraDoacaoPessoaMes({
    data_inicial,
    data_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaDoacao) {
    return await api
      .post('api/Consulta/ListPrimeiraDaPessoaNoMes', {
        data_inicial,
        data_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const primeiraDoacaoPessoaMes = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemPrimeiraDoacaoPessoaMes: IConsultaPrimeiraDoacaoPessoaMes[] = [];

        primeiraDoacaoPessoaMes.map((item: IConsultaPrimeiraDoacaoPessoaMes) => {
          return itemPrimeiraDoacaoPessoaMes.push({
            nome: item.nome,
            nome_fantasia: item.nome_fantasia,
            documento: item.documento,
            valor_primeira_doacao:  CurrencyFormat(parseFloat(item.valor_primeira_doacao)),
            data_primeira_doacao: FormatDate(item.data_primeira_doacao),

          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, primeiraDoacaoPessoaMes: itemPrimeiraDoacaoPessoaMes },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
    }

    public async buscaConsultaCadastroPorOperador({
      data_inicial,
      data_final,
      gerar_arquivo,
      page_number,
      page_size,
    }: IBuscaDetalhadaDoacao) {
      return await api
        .post('api/Consulta/ListCadastroPorOperadorPorPeriodo', {
          data_inicial,
          data_final,
          gerar_arquivo,
          page_number,
          page_size,
        })
        .then(response => {
          const cadastroOperador = response.data.modelo;
          const total_itens = response.data.total_itens;
          const total_pagina = response.data.total_pagina;
          const link_arquivo = response.data.link_arquivo;

          var itemCadastroOperador: IConsultaCadastroOperador[] = [];

          cadastroOperador.map((item: IConsultaCadastroOperador) => {
            return itemCadastroOperador.push({
              codigoUsuario: item.codigoUsuario,
              nomeUsuario: item.nomeUsuario,
              data_cadastro: item.data_cadastro,
              idCadastro: item.idCadastro,
              nomeCadastro: item.nomeCadastro,
              apelido: item.apelido,
              documento: item.documento,
              tipo_perfil: item.tipo_perfil,
              origem_cadastro: item.origem_cadastro,
              forma_contribuicao: item.forma_contribuicao,
              recorrente: item.recorrente,
              tipo_correspondencia: item.tipo_correspondencia,
              endereco: item.endereco,
              bairro: item.bairro,
              complemento: item.complemento,
              cidade: item.cidade,
              estado: item.estado,

            });
          });

          return {
            error: false,
            response: { total_itens, total_pagina, link_arquivo, cadastroOperador: itemCadastroOperador },
          };
        })
        .catch(error => {
          console.log('caiu aqui', error);

          return {
            error: true,
            response: [],
          };
        });
      }

  public async GetTodosConvenio() {
    return await api
      .get<IConvenio[]>(`api/Financeiro/GetFinanceiroConvenios`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GerarPDF() {
    return await api

      .post('api/Relatorio/GerarPDFV2')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

  public async buscaConsultaInativosSemContrib({
    data_procura_inicial,
    data_procura_final,
    gerar_arquivo,
    page_number,
    page_size,
  }: IBuscaDetalhadaInativos) {
    return await api
      .post('api/Consulta/FindInativoSemFormaContribuicaoByPeriodo', {
        data_procura_inicial,
        data_procura_final,
        gerar_arquivo,
        page_number,
        page_size,
      })
      .then(response => {
        const inativosSemContrib = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;
        const link_arquivo = response.data.link_arquivo;

        var itemInativosSemContrib: IConsultaInativosSemContrib[] = [];

        inativosSemContrib.map((item: IConsultaInativosSemContrib) => {
          return itemInativosSemContrib.push({
            id_pessoa: item.id_pessoa,
            nomeCompleto: item.nomeCompleto,
            FormaContribuicao: item.FormaContribuicao,
            datadecadastro: FormatDate(item.datadecadastro),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, link_arquivo, inativosSemFormaContrib: itemInativosSemContrib },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }

}

export default PageConsultasServices;

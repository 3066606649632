import api from '@http/api';


export interface IResumoDoacao {
  total_doacoes: number;
  primeira_doacao: string;
  ultima_doacao:string;
  media_doacoes:string;
}

interface IResponseResumoDoacao {
  error: boolean;
  response?: IResumoDoacao;
}

interface IRequestResumo {
  idPerfil: number;
}

interface IRequestTable{
  idPerfil: number;
  ultimaDoacaoId: number;
  qntItemsPagina: number;
}

export interface IModelo {
  data_doacao: string;
  origem_doacao_descricao: string;
  campanha_descricao: string;
  valor_doacao: string;
  observacao: string;
}

interface IResponse {
  error: boolean;
  response?:  IModelo[];

}


class Services {

  public async getResumoDoacao({
    idPerfil
  }: IRequestResumo): Promise<IResponseResumoDoacao> {
    return await api
      .get(`/api/Doacao/GetResumoDocoes?id_perfil=${idPerfil}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default Services;
